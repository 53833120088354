import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  killSessionTime = 1800000; // 30 Minutes in milliseconds;
  killSessionTimeInSec = 1800;
  sessionTime = new BehaviorSubject(0);

  constructor (private http: HttpService, private router: Router) { }

  setSessionTime() {
    this.sessionTime.next(this.killSessionTimeInSec);
  }

  getSessionTime() {
    return this.sessionTime.asObservable();
  }

  login(loginDat) {
    loginDat["email"] = this.encrypt(loginDat["email"]);
    loginDat["password"] = this.encrypt(loginDat["password"]);
    return this.http.apiAuth('/supplier/auth/login', loginDat);
  }

  loginAdmin(loginDat) {
    loginDat["email"] = this.encrypt(loginDat["email"], environment.authSecretKey);
    loginDat["password"] = this.encrypt(loginDat["password"], environment.authSecretKey);
    return this.http.apiAuth('/auth/login', loginDat);
  }

  loginAsSupplier(loginData) {
    return this.http.apiPostAdmin('/supplier/auth/admin/login', loginData);
  }

  signUp(id, signupForm, token?: string) {
    signupForm["password"] = this.encrypt(signupForm["password"]);
    if (token) {
      return this.http.apiPost(`/supplier/auth/resetPassword`, signupForm, token);
    } else {
      return this.http.apiPost(`/supplier/auth/user/${id}/resetPassword`, signupForm);
    }
  }

  validateToken(token) {
    return this.http.apiGetProtected('/auth/validateToken', token);
  }

  updateUser(updateForm) {
    return this.http.apiPut(`/user/me`, updateForm);
  }

  isLoggedIn() {
    return !!this.http.sessionStorageGetItem('supplier-token');
  }

  isIdentitySet() {
    return !!this.http.localStorageGetItem('supplier-identity');
  }

  getUser(id) {
    return this.http.apiGet(`/user/${id}`);
  }

  forgotPassword(email) {
    email = this.encrypt(email);
    return this.http.apiPost(`/supplier/auth/forgotPassword`, { email });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(["/auth"]);
  }

  verifyAccount(token) {
    const payload = {
      token
    }
    return this.http.apiPost(`/supplier/auth/verify`, payload);
  }

  activateAccount(id, email, password) {
    const payload = {
      email: this.encrypt(email),
      password: this.encrypt(password)
    };
    return this.http.apiPut(`/supplier/user/${id}/activate`, payload);
  }

  encrypt(val, key = null) {
    return CryptoJS.AES.encrypt(val, key? key : environment.ENCRYPTION_KEY).toString();
  }

  decrypt(val, key = null) {
    if (val) {
      const decrypted = CryptoJS.AES.decrypt(val, key ? key : environment.ENCRYPTION_KEY);
      try {
        return decrypted.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/auth/login']);
        location.reload();
      }
    }
    return null;
  }
}
