import { Component, HostListener, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { AuthService } from './service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Part Analytics - Supplier Portal';
  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor(private authService: AuthService, angulartics2Mixpanel: Angulartics2Mixpanel) {
    this.authService.setSessionTime();
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.logout();
    });
    angulartics2Mixpanel.startTracking();
  }

  logout() {
    this.authService.logout();
    location.reload();
  }
  setTimeout() {
    this.userActivity = setTimeout(() => {
      if (this.authService.isLoggedIn) {
        this.userInactive.next(undefined);
        console.log('logged out');
      }
    }, this.authService.killSessionTime);
  }
  @HostListener('window:mousemove') refreshUserState() {
    this.authService.setSessionTime();
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
