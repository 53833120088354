<div class="backdrop" [ngClass]="showModal ? 'd-block' : ''">
  <div class="modal fade" [ngClass]="showModal ? 'show d-block' : ''" id="exampleModalCenter" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content p-4">
        <h4>{{ alertTitle }}</h4>
        <p>{{ alertMessage }}</p>
        <div class="btn-grp text-right d-inline-block">
          <button class="primary-button" (click)="showModal = false">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>