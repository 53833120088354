import { Component, OnInit, EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  showModal = new EventEmitter();

  constructor() { }

  public show(title, message) {
    this.showModal.emit({
      state: true,
      title,
      message
    });
  }

  public hide() {
    this.showModal.emit(false);
  }
}
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit  {
  showModal = false;
  alertTitle: any;
  alertMessage: any;

  constructor(private alertService: AlertService) {
    this.alertService.showModal.subscribe(state => {
      this.showModal = state.state;
      this.alertTitle = state.title;
      this.alertMessage = state.message;
    });
  }

  ngOnInit() {
  }

}
