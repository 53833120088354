import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EncryptPipe } from '../shared/encrypt.pipe';
import { DecryptPipe } from '../shared/decrypt.pipe';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  API_URL = environment.API_URL;
  WF_URL = environment.WF_URL;

  constructor (private http: HttpClient, private encryptPipe: EncryptPipe, private decryptPipe: DecryptPipe) { }

  getHeaders() {
    const token = this.sessionStorageGetItem('supplier-token');
    const headers = {
      'Conetent-Type': 'Application/json',
      Authorization: `Bearer ${token}`
    };
    return headers;
  }

  getAdminHeaders() {
    const token = this.sessionStorageGetItem('admin-token');
    const headers = {
      'Conetent-Type': 'Application/json',
      Authorization: `Bearer ${token}`
    };
    return headers;
  }

  apiAuth(url, data) {
    return this.http.post(this.API_URL + url, data);
  }

  apiPost(url, data, token?: string) {
    if (token) {
      const headers = {
        'Conetent-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      };
      return this.http.post(this.API_URL + url, data, { headers });
    } else {
      const headers = this.getHeaders();
      return this.http.post(this.API_URL + url, data, { headers });
    }
  }

  apiPostWorkflow(url, data, token?: string) {
    if (token) {
      const headers = {
        'Conetent-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      };
      return this.http.post(this.WF_URL + url, data, { headers });
    } else {
      const headers = this.getHeaders();
      return this.http.post(this.WF_URL + url, data, { headers });
    }
  }

  apiPostAdmin(url, data) {
    const headers = this.getAdminHeaders();
    return this.http.post(this.API_URL + url, data, { headers });
  }

  apiPut(url, data) {
    const headers = this.getHeaders();
    return this.http.put(this.API_URL + url, data, { headers });
  }

  apiPutWorkflow(url, data) {
    const headers = this.getHeaders();
    return this.http.put(this.WF_URL + url, data, { headers });
  }

  apiGet(url) {
    const headers = this.getHeaders();
    return this.http.get(this.API_URL + url, { headers });
  }

  apiGetWorkflow(url) {
    const headers = this.getHeaders();
    return this.http.get(this.WF_URL + url, { headers });
  }
  
  apiGetAdmin(url) {
    const headers = this.getAdminHeaders();
    return this.http.get(this.API_URL + url, { headers });
  }

  apiGetFile(url) {
    const headers = this.getHeaders();
    return this.http.get(this.API_URL + url, { headers: headers, responseType: "blob" });
  }

  apiPostFile(url, data) {
    const headers = this.getHeaders();
    return this.http.post(this.API_URL + url, data, { headers: headers, responseType: 'blob' });
  }

  apiGetProtected(url, token?:string) {
    if (token) {
      const headers = {
        'Conetent-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      };
      return this.http.get(this.API_URL + url, { headers });
    } else {
      const headers = this.getHeaders();
      return this.http.get(this.API_URL + url, { headers });
    }
  }

  apiDelete(url) {
    const headers = this.getHeaders();
    return this.http.delete(this.API_URL + url, { headers });
  }

  
  localStorageSetItem(key, val) {
    key = this.encryptPipe.transform(key, {"hash": true});
    val = this.encryptPipe.transform(val);
    localStorage.setItem(key, val);
  }

  localStorageGetItem(key) {
    key = this.encryptPipe.transform(key, {"hash": true});
    return this.decryptPipe.transform(localStorage.getItem(key));
  }

  sessionStorageSetItem(key, val) {
    key = this.encryptPipe.transform(key, {"hash": true});
    val = this.encryptPipe.transform(val);
    sessionStorage.setItem(key, val);
  }

  sessionStorageGetItem(key) {
    key = this.encryptPipe.transform(key, {"hash": true});
    return this.decryptPipe.transform(sessionStorage.getItem(key));
  }

  sessionStorageRemoveItem(key) {
    key = this.encryptPipe.transform(key, {"hash": true});
    sessionStorage.removeItem(key);
  }

  localStorageRemoveItem(key) {
    key = this.encryptPipe.transform(key, {"hash": true});
    localStorage.removeItem(key);
  }

  apiGetProtectedWorkflow(url) {
    const headers = this.getHeaders();
    return this.http.get(this.WF_URL + url, { headers });
  }
}
