import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { AuthGuard } from './components/authguard/auth.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptorService } from './components/loader/loader-interceptor.service';
import { LoaderComponent } from './components/loader/loader.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertComponent } from './shared/alert/alert.component';
import { ToastrModule } from 'ngx-toastr';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EncryptPipe } from './shared/encrypt.pipe';
import { DecryptPipe } from './shared/decrypt.pipe';
@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    AlertComponent,
    EncryptPipe,
    DecryptPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        autoTrackVirtualPages: false,
        clearIds: true,
      },
      developerMode: false,
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AngularSlickgridModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    AuthGuard,
    EncryptPipe,
    DecryptPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
