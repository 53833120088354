// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://pastageapi.partanalytics.com/api/v1',
  storageEncryptDecryptKey: "b26090ae-6947-47e0-8d4e-0708186e80e8",
  ENCRYPTION_KEY : "C&BOCE7Pl!9O9&B$Aw*b",
  authSecretKey: "blwruwRicrE7tOmocH*D",
  WF_URL:"https://pastageapi.partanalytics.com/api/v1",
  IPIFY_URL:'https://api.ipify.org/?format=json',
  GEO_LOCATION_DATA_URL: 'https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
