import { Pipe, PipeTransform } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'encrypt'
})
export class EncryptPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args && args["hash"]) {
      return value ? CryptoJS.MD5(value).toString() : null;
    }
    return value ? CryptoJS.AES.encrypt(value, args && args["key"] ? environment[args.key] : environment.storageEncryptDecryptKey).toString() : null;
  }

}
