import { Injectable } from '@angular/core';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    constructor(private angulartics2Mixpanel: Angulartics2Mixpanel) {}
    startTracking(): void {
        this.angulartics2Mixpanel.startTracking();
    }
    
    pageTrack(path: string): void {
        this.angulartics2Mixpanel.pageTrack(path);
    }

    eventTrack(action: string, properties: any): void {
        this.angulartics2Mixpanel.eventTrack(action, properties);
    }

    setUsername(userId: string): void {
        this.angulartics2Mixpanel.setUsername(userId);
    }

    setUserProperties(properties: any): void {
        this.angulartics2Mixpanel.setUserProperties(properties);
    }

    setUserPropertiesOnce(properties: any): void {
        // this.angulartics2Mixpanel.setUserPropertiesOnce(properties);
    }

    setSuperProperties(properties: any): void {
        // this.angulartics2Mixpanel.setSuperProperties(properties);
    }

    setSuperPropertiesOnce(properties: any): void {
        // this.angulartics2Mixpanel.setSuperPropertiesOnce(properties);
    }
    
    setAlias(alias: any): void {
        // this.angulartics2Mixpanel.setAlias(alias);
    }
}