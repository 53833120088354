<!-- <div [hidden]="!show">
  <div class="loader-overlay">
    <div class="loader"></div>
  </div>
</div> -->
<div [hidden]="!show">
  <div class="backdrop">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
