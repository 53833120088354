import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../service/auth.service';
import { AnalyticsService } from 'src/app/service/analytics.service';
import { HttpService } from '../../service/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  user: any;
  isNewUser: boolean;
  constructor(private authService: AuthService, private router: Router, private analyticsService: AnalyticsService, private httpService: HttpService){ }  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.authService.isLoggedIn()) {
        if (!this.authService.isIdentitySet()) {
          const data = JSON.parse(this.httpService.localStorageGetItem('supplier-user'));
          try {
            this.analyticsService.setUsername(data['_id']);
            this.httpService.localStorageSetItem('supplier-indentity', 'true');
            this.analyticsService.setUserProperties({
              '$email': data['email'],
              '$phone': data['phoneNumber'],
              '$first_name': data['firstName'],
              '$last_name': data['lastName']
            });
          } catch(e) {
            // ignore this error
            console.log("Error", e);
          }
        }
        return true;
      } else {
        if (this.router.url.endsWith('admin')) {
          return true;
        }
        if (state.url.startsWith('/dashboard/rfq/') && !(state.url.includes('/messages'))) {
          const urlParts = state.url.split('/');
          if (urlParts.length && urlParts[5]) {
            this.verifyAccount(urlParts[5], state);
          }
        } else {
          this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } })
        }
        return false;
      }
  }

  verifyAccount(token, state) {
    this.authService.verifyAccount(token).subscribe(res => {
      this.isNewUser = res && res['data'] && res['data']['newUser'] ? true : false;
      if(this.isNewUser){
        this.router.navigate([`/auth/activate/${token}`]);
      } else {
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } })
      }
    })
  }
}
