import { Pipe, PipeTransform } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Pipe({
  name: 'decrypt'
})
export class DecryptPipe implements PipeTransform {

  constructor(private router: Router) {}

  transform(value: any, args?: any): any {
    if (value) {
      const decrypted = CryptoJS.AES.decrypt(value, environment.storageEncryptDecryptKey);
      try {
        return decrypted.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(["/auth"]);
        location.reload();
      }
    }
    return null;
  }
}
